import React, { useEffect, useState } from "react";
import IRANFlag from "@/public/icons/IRANFlag.svg";
import UKFlag from "@/public/icons/UKFlag.svg";
import { useRouter } from "next/router";
import Link from "next/link";

export default function FlagButton(props) {
  let { className = "" } = props;
  const router = useRouter();
  return (
    <Link
      href={router}
      locale={router.locale=="en"?"fa":"en"}
      dir="ltr"
      className={
        "h-10 py-1 px-6 pl-0 text-black inline-flex items-center border-2 rounded-md border-gray-light hover:border-gray-light-2 transition-all ml-4 rtl:ml-0 rtl:mr-4 " +
        className
      }
    >
      {router.locale == "en" ? (
        <IRANFlag className="mr-1"></IRANFlag>
      ) : (
        <UKFlag className="mr-1"></UKFlag>
      )}{" "}
      {router.locale == "en" ? "FA" : "EN"}
    </Link>
  );
}
