import React, { useRef, useState } from 'react'
import UnvisibleIcon from "@/public/icons/UnvisibleIcon.svg";
import AlertIcon from "@/public/icons/AlertIcon.svg";
import { useFormContext } from 'react-hook-form';
import { useTranslation } from 'next-i18next';

const Input = (props) => {
    const {
        id,
        placeholder = '',
        label = '',
        type = 'text',
        error = false,
        errorText = '',
        required = false,
        className = '',
        wrapperClassName='',
        validatorRegex='',
        errorMsg='',
        Icon=null,
        iconStyle="",
        name="input",
        disabled=false,
        fullWidth=false,
        validateFunc=null,
        ...rest
    } = props;
    const { register,formState: { errors } } = useFormContext(); 
    const [inputType,setInputType]=useState(type);
    const {t}=useTranslation("common");
    const inputRef = useRef();
  return (
    <>
    <span className={`relative block ${wrapperClassName}`}>
    <input
        ref={inputRef}
        type={inputType}
        className={`w-full block h-10 px-4 py-1.5 text-black outline-none font-open-sans rtl:font-dana-r text-base ${disabled?"!bg-gray-light":""} font-light rounded-lg border border-gray-light-5 `+className}
        id={id}
        placeholder={placeholder}
        {...register(name, {
          required: required?t("input_required"):"",
          pattern: {
            value: validatorRegex,
            message: errorMsg
          },
          validate:validateFunc
          
        })}
        disabled={disabled}
        autoComplete='off'
        {...rest}
    />
    {Icon && <Icon className={iconStyle}></Icon>}
    {type==="password" && <UnvisibleIcon onTouchStart={()=>setInputType("text")} onTouchEnd={()=>setInputType("password")} onMouseDown={()=>setInputType("text")} onMouseUp={()=>setInputType("password")} className="absolute right-3 rtl:left-3 rtl:right-auto top-2 cursor-pointer"></UnvisibleIcon>}
    {errors && errors[name] && <small className='absolute text-red-2'>{errors[name].message}</small>}
    {errors && errors[name] && <span className={`absolute right-1 rtl:right-auto rtl:left-1 top-1 bg-red-2 p-1 rounded`}><AlertIcon></AlertIcon></span>}
    </span>
    
    </>
  )
}

export default Input